import React, { useMemo } from 'react';
import { Link, useLocation } from '@reach/router';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { Company, RoleSettingsDTO } from '@types';
import { Badge } from '@kit/ui/Badge';
import { Container, Content, DisabledLink, ItemWithBadge, NavGroup, NavGroupName, Sidebar } from './styled';

const MENU_ITEMS = [
  {
    title: 'Workspace settings',
    items: [
      {
        title: 'General',
        path: 'general'
      },
      {
        title: 'Roles',
        path: 'roles'
      },
      {
        title: 'Members',
        path: 'users'
      },
      {
        title: 'Teams',
        path: 'teams',
        canView: (access: RoleSettingsDTO) => hasAccess(access, 'team', 'view')
      },
      {
        title: 'Notifications',
        path: 'notifications'
      },
      {
        title: 'Emails & Phones',
        path: 'emails-and-phones',
        canView: (_access: RoleSettingsDTO, company: Company) =>
          company?.settings?.features?.email || company?.settings?.features?.sms
      }
    ]
  },
  {
    title: 'Properties',
    items: [
      {
        title: 'Client properties',
        path: 'properties/client'
      },
      {
        title: 'Request properties',
        path: 'properties/request'
      },
      {
        title: 'Project properties',
        path: 'properties/project'
      }
    ]
  },
  {
    title: 'Configure',
    items: [
      {
        title: 'Workflows',
        path: 'workflows'
      },
      {
        title: 'Stages',
        path: 'stages'
      },
      {
        title: 'Templates',
        path: 'templates/workOrders',
        paths: ['templates/workOrders', 'templates/forms', 'templates/actionItemTemplates']
      },
      {
        title: 'Automations',
        path: 'automations'
      },
      {
        title: 'Labels',
        path: 'labels'
      },
      {
        title: 'Catalog',
        path: 'catalog'
      }
    ]
  },
  {
    title: 'Advanced settings',
    items: [
      {
        title: 'Systems',
        path: 'systems',
        isBeta: true,
        isActive: (_access: RoleSettingsDTO, company: Company) => !!company?.settings?.features?.fleet
      },
      {
        title: 'Portal',
        path: 'portal',
        canView: (_access: RoleSettingsDTO, company: Company) => company?.customerPortalEnabled
      },
      {
        title: 'Geolocation',
        path: 'geolocation',
        isNew: true,
        canView: (_access: RoleSettingsDTO, company: Company) => !!company?.settings?.features?.geolocation
      },
      {
        title: 'Integrations',
        path: 'integrations',
        isBeta: true
      }
    ]
  }
];

interface Props {
  children: React.ReactNode;
}

export const WorkspaceSettingsLayout = ({ children }: Props) => {
  const location = useLocation();

  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);
  const { data: access } = useUserRoleSettings(companyId, userId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const canViewSettings = hasAccess(access, 'workspace', 'view', 'settings');

  const filteredMenuItems = useMemo(() => {
    return MENU_ITEMS.map((group) => ({
      title: group.title,
      items: group.items.filter((item) => {
        if (item.canView && !item.canView(access, company)) {
          return false;
        }

        return true;
      })
    })).filter((group) => group.items.length > 0);
  }, [access, company]);

  if (!canViewSettings) {
    return null;
  }

  return (
    <Container>
      <Sidebar>
        {filteredMenuItems.map((group) => (
          <NavGroup key={group.title}>
            <NavGroupName>{group.title}</NavGroupName>
            {group.items.map((item) => {
              if ('isSoon' in item) {
                return (
                  <ItemWithBadge>
                    {item.title}
                    <Badge color="#fff" bgColor="#f0bc4d">
                      Soon!
                    </Badge>
                  </ItemWithBadge>
                );
              }

              const isCurrentPath =
                location.pathname.endsWith(item.path) ||
                ('paths' in item && item.paths.some((path) => location.pathname.endsWith(path)));

              return 'isActive' in item && !item.isActive(access, company) ? (
                <ItemWithBadge>
                  <DisabledLink>{item.title}</DisabledLink>
                  {'isNew' in item && (
                    <Badge color="#fff" bgColor="#d54855">
                      New
                    </Badge>
                  )}

                  {'isBeta' in item && (
                    <Badge color="#4D7CFE" bgColor="#DBE5FF">
                      Beta
                    </Badge>
                  )}
                </ItemWithBadge>
              ) : (
                <ItemWithBadge>
                  <Link className={isCurrentPath ? 'active' : undefined} key={item.title} to={item.path}>
                    {item.title}
                  </Link>
                  {'isNew' in item && (
                    <Badge color="#fff" bgColor="#d54855">
                      New
                    </Badge>
                  )}

                  {'isBeta' in item && (
                    <Badge color="#4D7CFE" bgColor="#DBE5FF">
                      Beta
                    </Badge>
                  )}
                </ItemWithBadge>
              );
            })}
          </NavGroup>
        ))}
      </Sidebar>
      <Content key={location.pathname}>{children}</Content>
    </Container>
  );
};
